<template>
    <f7-page name="home" class="p-index page-bg-2area-flow p-home">
        <!-- Top Navbar -->
        <f7-navbar noShadow noHairline class="navbar-index navbar-no-bg-when-no-scroll">
            <f7-nav-left>
                <!-- <f7-link class="round-icon-btn" @click="f7.view.main.router.navigate('/notification', {
                    reloadCurrent: true,
                    ignoreCache: true,
                })">
                    <i class="icon-bell"></i>
                </f7-link> -->
                <a href="" class="new-logo"><img src="@/assets/images/logo/logo.svg" alt=""></a>
            </f7-nav-left>

            <f7-nav-title style="color:#fff;">
                南投愛行
            </f7-nav-title>

            <f7-nav-right>
                <f7-link class="">
                    <!-- <NavStatusBadge 
                        :ongoing="pageData.HasExecutingOrder.OrderNo ? true : null" 
                        :complete="pageData.HasExecutingOrder.OrderNo ? null : true" 
                        @click="m_direct_page('/fleet_on_map')"
                    /> -->
                </f7-link>
            </f7-nav-right>
        </f7-navbar>

        <!-- Page content-->
        <div class="index-swiper">
            <f7-swiper id="swiper-index" :loop="true" :centeredSlides="true" :spaceBetween="0" :slidesPerView="1.3"
                :autoplay="{ delay: 8000 }">
                <f7-swiper-slide v-for="item in pageData.Ad" :key="item.Id">
                    <f7-link :prevent-router="true" href="javascript:;" @click="pageData.simpleNotifyPopup.open({
                        Title: item.Title,
                        Message: item.Content
                    })">
                        <!-- 768x330 -->
                        <img class="" :src="item.ImageUrl" alt="" />
                    </f7-link>
                </f7-swiper-slide>
                <f7-swiper-slide v-if="pageData.Ad.length === 0 && pageData.ajaxDone">
                    <f7-link :prevent-router="true" href="javascript:;">
                        <img class="" src="@images/preview/index_banner.jpg" alt="" />
                    </f7-link>
                </f7-swiper-slide>
            </f7-swiper>
        </div>

        <BaseInner>
            <div class="index-menu-bed">
                <!-- <img class="bed-bg" src="@/assets/images/index/index-menu-bed.svg" alt="" srcset=""> -->
                <!-- <img v-if="!canBooking" class="btn-link-disable-overlay" src="@/assets/images/general/gray-overlay.svg"
                    alt="" srcset=""> -->
                <f7-link class="bed-link link-1" @click="bookCab(false)">
                    <div class="link-inner">
                        <img class="img-1" src="@/assets/images/index/taxi2.png" alt="" srcset="">
                        <div class="link-txt">
                            <!-- {{ pageData.bookCabTextDisplay }} -->計程車
                        </div>
                    </div>
                </f7-link>
                <!-- 目前都先導到一般計程車部分 -->
                <f7-link class="bed-link link-2" @click="bookCab(true)">
                    <div class="link-inner">
                        <img class="img-2" src="@/assets/images/index/taxi.png" alt="" srcset="">
                        <div class="link-txt">
                            敬老愛心卡<br>計程車
                        </div>
                    </div>
                </f7-link>
                <!--<f7-link class="bed-link link-3" @click="bookPeriodCab()" :class="{
                    'bed-link_disabled': (!canBooking)
                }">
                    <div class="link-inner">
                        <img class="img-3" src="@/assets/images/index/calendar.svg" alt="" srcset="">
                        <div class="link-txt">
                            固定時段訂車
                        </div>
                    </div>
                </f7-link> 
                <f7-link class="bed-link link-4" href="/charted_car_fleet">
                    <div class="link-inner">
                        <img class="img-4" src="@/assets/images/index/map.svg" alt="" srcset="">
                        <div class="link-txt">
                            包車
                        </div>
                    </div>
                </f7-link>-->
            </div>

            <div class="fleet-info-preview-box" v-if="executingOrder.HasExecutingOrder.OrderNo">
                <div class="box-back">
                    <div class="box-status">
                        執行中
                    </div>
                </div>
                <div class="box-front">
                    <f7-link class="front-left" @click="viewFleetOnMap();">
                        <div class="box-info-item">
                            <div class="item-title">
                                車隊
                            </div>
                            <div class="item-content">
                                {{ executingOrder.HasExecutingOrder.CopmpanyName || '-' }}
                            </div>
                        </div>
                        <div class="box-info-item">
                            <div class="item-title">
                                車號
                            </div>
                            <div class="item-content">
                                {{ executingOrder.HasExecutingOrder.LicensePlateNumber || '-' }}
                            </div>
                        </div>
                    </f7-link>
                    <div class="front-right">
                        <div class="box-tools">
                            <div class="tools-item" @click="f7.view.main.router.navigate('/contact_driver')">
                                <f7-link class="tools-msg">
                                    <img class="img-msg" src="@/assets/images/icon/msg.svg" alt="" srcset="">
                                    <NumBadge :num="unreadNum" size="20px" />
                                </f7-link>
                            </div>
                            <f7-link class="tools-item external"
                                @click="m_dial(executingOrder.OrderRecordDetail.DriverPhoneNumber)">
                                <img class="img-phone" src="@/assets/images/icon/phone.svg" alt="" srcset="">
                            </f7-link>
                        </div>
                    </div>
                </div>
            </div>

            <div class="fast-appoint-wrap">
                <div class="fast-appoint">
                    <div class="appoint-title">
                        快速預約
                    </div>
                
                    <!-- <MukiTabbar :tabs="pageData.tabs" :activeId="pageData.activeTabId" @tabClick="switchTab($event)"
                    @init="setMukiTabbar($event)" href tinySpace elegant pills segmented
                    tabContentSelector="#fast-book" :class="{ active: isActive }" @click="toggleActive"/> -->
                
                </div>
                


                <BaseSpinner blockCenter v-if="(!pageData.Record || !pageData.Record.length) && !ajaxDone" />
                <BaseList vgap="10px">
                    <div class="list-item" v-for="item in pageData.Record" :key="item.MessageId">
                        <RouteCard noShadow :date="moment(item.CallTaxiDateTime).format('YYYY-MM-DD HH:mm')"
                        :start="item.StartAddress" :end="item.TargetAddress" activeState
                        @click="fastBook(item)" />
                    </div>
                    <div class="list-item" v-if="(!pageData.Record || !pageData.Record.length) && ajaxDone">
                        <div class="text-align-center" style="padding: 10px;background: #fff; border-radius: 7px; ">
                            目前尚無資料
                        </div>
                    </div>
                </BaseList>
            </div>
            
            <BaseSpacer h="30px" />
        </BaseInner>

        <ModalStyPopup noCloseBtn noHeadBorder light title="即時訂車功能提醒" id="casual-book-prompt">
            若超過預約訂車時間，可使用此功能。<br>
            提醒您！<br>
            即時訂車會於<span class="text-theme">搭車前20分鐘</span>通知派車結果，因車隊車輛規模有限，不保證成功派車，敬請見諒。
            <br>
            可於搭乘紀錄中查詢派車狀態，謝謝！
            
            <BaseSpacer h="20px" />
            <BaseButton class="btn-theme" pill shadow lg @click="casualPromptOK()">
                我知道了！
            </BaseButton>
        </ModalStyPopup>

        <ModalStyPopup noCloseBtn noHeadBorder light :title="`${pageData.bookCabTextDisplay}訂車功能提醒`" id="book-prompt">
            提醒您！<br> 最晚須在<span class="text-theme">搭車前一天14:00前</span>完成預約，若預約完成，最晚會於<span
                class="text-theme">搭車前一天20:00完成派車</span>。
            <br>
            可於搭乘紀錄中查詢派車狀態，謝謝！

            <BaseSpacer h="20px" />
            <BaseButton class="btn-theme" pill shadow lg @click="bookCabPromptOK()">
                我知道了！
            </BaseButton>
        </ModalStyPopup>

        <ModalStyPopup noCloseBtn noHeadBorder light noTitleLeft noTitleRight title="固定時段訂車功能提醒"
            id="book-period-prompt">
            若有每周特定時間用車需求，可使用此功能。<br>
            提醒您！此功能僅能預約<span class="text-theme">未來14天內</span>的車，並可<span class="text-theme">自行勾選無趟次時轉候補單</span>。
            <br>
            預約成功後會於<span class="text-theme">用車前一天20:00前派車</span>；若轉候補單則最慢會於<span
                class="text-theme">用車前一天18:00通知候補結果</span>，因車隊車輛規模有限，不保證成功派車，敬請見諒。
            <br>可於搭乘紀錄中查詢派車狀態，謝謝！

            <BaseSpacer h="20px" />
            <BaseButton class="btn-theme" pill shadow lg @click="bookPeriodCabPromptOK()">
                我知道了！
            </BaseButton>
        </ModalStyPopup>

        <SimpleNotifyPopup @init="setSimpleNotifyPopup($event)" />

        <!-- <RideRatePopup /> -->
    </f7-page>
</template>

<script>
import { reactive, ref, onMounted, computed, nextTick } from "vue";
import { f7ready, PageContent } from 'framework7-vue';
// import { useI18n } from "vue-i18n";
import { useStore } from 'vuex';
import { checkResources, checkGps, getExecutingOrderDetail } from '@functions'
import { Order, CallTaxi, Config, Notify, Contact } from "@baseService";
import NavStatusBadge from "@projectComponents/NavStatusBadge";
import NumBadge from "@components/NumBadge";
import MukiTabbar from "@components/MukiTabbar";
import RouteCard from "@projectComponents/RouteCard";
import AddressCard from "@projectComponents/AddressCard";
import ModalStyPopup from "@projectComponents/ModalStyPopup";
import RideRatePopup from "@/popup/RideRatePopup";
import SimpleNotifyPopup from "@projectComponents/SimpleNotifyPopup";

export default {
    components: {
        NavStatusBadge,
        MukiTabbar,
        RouteCard,
        AddressCard,
        NumBadge,
        ModalStyPopup,
        RideRatePopup,
        SimpleNotifyPopup
    },
    props: {
    },
    setup() {
        // 後端取得 mobile info 用
        _get_mobile_info();

        const store = useStore()

        // store.dispatch('booking/init');

        const unreadNum = store.getters['getUnread']
        const bookingData = store.getters['booking/getBookingData']
        const executingOrder = store.getters['executingOrder/getData'];

        const ajaxDone = ref(false);

        const canBooking = computed(() => {
            return store.getters['auth/canBooking'];
        });

        const pageData = reactive({
            simpleNotifyPopup: {},
            bookCabTextDisplay: computed(() => {
                return store.getters['auth/bookCabText'];
            }),
            tabs: [
                {
                    id: 0,
                    name: '紀錄'
                },
                {
                    id: 1,
                    name: '常用'
                }
            ],
            activeTabId: '',
            //歷史記錄地點選單
            Record: [
                // {
                //     MessageId	string
                //     StartAddress	string
                //     TargetAddress	string
                //     CallTaxiDateTime	string
                //     StartLatitude*	number($double)
                //     StartLongitude*	number($double)
                //     TargetLatitude*	number($double)
                //     TargetLongitude*	number($double)
                //     FareCost*	number($decimal)
                //     CallOrderNo	string
                //     FlowStatus*	integer($int32)
                //     TaxiCallNo	string
                //     DriverName	string
                //     DriverId	string
                //     DriverImage	string
                //     StartAdditionMessage	string
                //     TargetAdditionMessage	string
                // }
            ],
            // 取得用戶常用地點
            GetAddressBooks: [
                // {
                //     Id*	integer($int32)
                //     AspNetUserId	string
                //     AddressType*	integer($int32)
                //     Address	string
                //     Lat*	number($double)
                //     Lng*	number($double)
                //     LastUseDate*	string($date-time)
                //     AdditionMessage	string
                // }
            ],
            Ad: [

            ]
        });

        let btnActiveName = ref("");
        let IndexSwiper;
        let IndexSwiperTimeout;
        let mukiTabbar;

        function viewFleetOnMap() {

            if (executingOrder.notifyType === mukiConst.notifyType.PAYING) {
                // 已經到達目的地

                // 跳轉評價頁
                f7.view.main.router.navigate('/ride_rate', {
                    props: {
                        orderNo: executingOrder.HasExecutingOrder.OrderNo,
                        orderId: executingOrder.HasExecutingOrder.OrderId,
                    }
                });

            } else {

                // 先取得當前訂單最新資料，再前往頁面
                if (executingOrder.HasExecutingOrder && executingOrder.HasExecutingOrder.OrderNo) {
                    getExecutingOrderDetail(executingOrder.HasExecutingOrder.OrderNo).then(() => {
                        f7.view.main.router.navigate('/fleet_on_map');
                    })
                }

            }
        }

        function slideChangeTransitionStart(swiper) {
            if (swiper.realIndex == 1) {
                mukiTabbar.setActive(1)
            } else {
                mukiTabbar.setActive(0)
            }
        }

        function onSwiper(swiper) {
            IndexSwiper = swiper;
        }

        function setMukiTabbar(event) {
            mukiTabbar = event
            mukiTabbar.setActive(0)
        }

        function switchTab(tab) {
            if (tab.id === 0) {
                IndexSwiper.slideTo(0)
            } else {
                IndexSwiper.slideTo(1)
            }
        }

        var psCallRecord = CallTaxi.CallRecord({ start: 1 }).then((res) => {

            ajax200Res(
                res,
                (res, data, msg) => {
                    // 成功
                    if (data) {
                        pageData.Record = data
                    }
                },
                (res, data, msg) => {
                    // 例外失敗
                    if (msg) {
                        f7.dialog.alert(msg, "訊息提示", () => {
                        });
                    }
                }
            )

        })


        function getAddressBook() {

            return Config.GetAddressBooks().then((res) => {

                ajax200Res(
                    res,
                    (res, data, msg) => {
                        // 成功
                        if (data) {
                            pageData.GetAddressBooks = data
                        }
                    },
                    (res, data, msg) => {
                        // 例外失敗
                        if (msg) {
                            f7.dialog.alert(msg, "訊息提示", () => {
                            });
                        }
                    }
                )

            })
        }

        var psGetAddressBooks = getAddressBook()

        Config.Ad().then(res => {
            ajax200Res(
                res,
                (res, data, msg) => {
                    // 成功
                    pageData.Ad = data
                },
                (res, data, msg) => {
                    // 例外失敗
                    if (msg) {
                        f7.dialog.alert(msg, "訊息提示", () => {
                        });
                    }
                }
            )
        })

        Promise.all([psCallRecord, psGetAddressBooks]).then(() => {
            nextTick(() => {
                if (IndexSwiper) {
                    IndexSwiper.update();
                }
            })
        }).finally(() => {
            ajaxDone.value = true
        })

        // 多國語系
        // const { t, locale} = useI18n({
        //     messages: {
        //         "tw": {
        //         },
        //         en: {
        //         },
        //     },
        // });


        function casualBook() {
            f7.popup.open('#casual-book-prompt');
        }

        function casualPromptOK() {
            f7.popup.close('#casual-book-prompt');
            m_direct_page('/book_casual_cab')
        }

        /**
         * 叫車
         * @param {Boolean} UseCaringEasyCar 是否愛心計程車
         */
        function bookCab(caringEasyCar) {
            store.commit('booking/setBookingData', {
                caringEasyCar
            });
            m_direct_page('/book_cab');
        }

        function bookCabPromptOK() {
            f7.popup.close('#book-prompt');
            m_direct_page('/book_cab')
        }

        function bookPeriodCab() {
            f7.popup.open('#book-period-prompt');
        }

        function bookPeriodCabPromptOK() {
            f7.popup.close('#book-period-prompt');
            m_direct_page('/book_period_cab')
        }


        onMounted(() => {

            // 進到首頁確認gps功能
            checkGps();

            // preload
            checkResources(() => typeof google !== 'undefined', 'https://maps.googleapis.com/maps/api/js?key=' + mukiConst.GCP_API_KEY + '&region=TW&language=zh-TW&libraries=geometry,places')
            checkResources(() => typeof GoogleMap !== 'undefined', 'assets/js/libs/muki_map.js');
        })

        function removeAddr(address) {

            f7.dialog.confirm('確定刪除常用地址?', "訊息提示", () => {
                Config.RemoveAddressBook({
                    listId: address.Id
                }).then(res => {

                    ajax200Res(
                        res,
                        (res, data, msg) => {
                            // 成功
                            getAddressBook().then(res => {
                                nextTick(() => {
                                    if (IndexSwiper) {
                                        IndexSwiper.update()
                                    }
                                })
                            })
                        },
                        (res, data, msg) => {
                            // 例外失敗
                            if (msg) {
                                f7.dialog.alert(msg, "訊息提示", () => {
                                });
                            }
                        }
                    );

                })
            });
        }

        function fastBook(address) {
            store.commit('booking/setBookingData', {
                caringEasyCar:true
            });
            store.commit('booking/setLocation', {
                StartAddress: address.StartAddress,
                BookingStartLat: address.StartLatitude,
                BookingStartLong: address.StartLongitude,
                AdditionMessage: address.StartAdditionMessage,

                TargetAddress: address.TargetAddress,
                BookingTargetLat: address.TargetLatitude,
                BookingTargetLong: address.TargetLongitude,
                TargetAdditionMessage: address.TargetAdditionMessage,
            });

            m_direct_page('/book_cab');
        }

        function fastBook2(address) {

            store.commit('booking/setBookingData', {
                StartAddress: address.Address,
                AdditionMessage: address.AdditionMessage,
                BookingStartLat: address.Lat,
                BookingStartLong: address.Lng,
            });

            m_direct_page('/book_cab');
        }

        function setSimpleNotifyPopup(popup) {
            pageData.simpleNotifyPopup = popup
        }

        return {
            store,
            mukiConst,
            bookingData,
            executingOrder,
            parseInt,
            pageData,
            btnActiveName,
            Boolean,
            f7,
            switchTab,
            casualBook,
            casualPromptOK,
            m_direct_page,
            fastBook,
            fastBook2,
            ajaxDone,
            unreadNum,
            moment,
            slideChangeTransitionStart,
            onSwiper,
            setMukiTabbar,
            removeAddr,
            m_dial,
            canBooking,
            viewFleetOnMap,
            setSimpleNotifyPopup,
            bookCab,
            bookCabPromptOK,
            bookPeriodCab,
            bookPeriodCabPromptOK
        };
    },
};

$(function(){
    $('.toolbar').find('.tab-link').click(function(){
        $(this).toggleClass('active').siblings('.tab-link').removeClass('active')
        return false;
    });
});
</script>

<style lang="scss">
@import "@design";

.ios,
.md {
    .p-index {
        --f7-navbar-height: var(--navbar-height);

        .page-content {
            overflow-x: hidden;
        }

        .route-card {
            padding: 8px 23px;

            .bar-inner {
                padding: 7px 0
            }
        }

        @include media-up(376) {
            --page-bg-2area-size: #{rwd-val(376px, 290px, 768px, 520px)};
        }
    }
}

.swiper-overlay {
    display: block;
    width: 100%;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMzc1IiBoZWlnaHQ9IjYzLjMzMyIgdmlld0JveD0iMCAwIDM3NSA2My4zMzMiPjxkZWZzPjxzdHlsZT4uYSwuYywuZHtmaWxsOiNmZmY7fS5ie29wYWNpdHk6MC44O2NsaXAtcGF0aDp1cmwoI2EpO30uY3tvcGFjaXR5OjAuMzt9LmR7b3BhY2l0eTowLjU7fTwvc3R5bGU+PGNsaXBQYXRoIGlkPSJhIj48cGF0aCBjbGFzcz0iYSIgZD0iTTQ0NSwzMDk3LjkxM3MxNDMuMjUsMy4yLDIzNywwLDEzOC0xMi43ODYsMTM4LTEyLjc4NlYzMDM2SDQ0NVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00NDUgLTI3MTkpIi8+PC9jbGlwUGF0aD48L2RlZnM+PGcgY2xhc3M9ImIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgLTMxNykiPjxwYXRoIGNsYXNzPSJjIiBkPSJNODE5LDMzMTNjLTEzOC0xMjYtMzc0LDAtMzc0LDB2NTJIODE5WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ0NSAtMjk0MCkiLz48cGF0aCBjbGFzcz0iZCIgZD0iTTYzOSwzMzM0Yy01OC00OS0xOTQtNjAtMTk0LTYwdjkySDYzOVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00NDUgLTI5NDApIi8+PC9nPjwvc3ZnPg==);
    background-position: center bottom;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 10%;
    z-index: 10;
    padding-top: 16.804%;
}

// Swiper Banner
.skeleton-block {
    position: relative;
    padding-top: calc(358 / 768 * 100%);

    .swiper-container {
        @include absolute(0, 0, 0, 0);
    }

    ~.wav {
        height: 32px;
        min-height: 32px;
        // background: url('@/assets/images/general/wave.png');
        background-repeat: repeat-x;
        background-size: auto 100%;
        width: 100%;
        margin-top: -10px;
        position: relative;
        z-index: 1;
    }
}


.index-swiper {
    margin-bottom: calc(17 / 14 * 1rem);
    filter: drop-shadow(0 3px 6px rgba(#000, 0.16));

    .swiper-slide {
        overflow: hidden;
        transition: transform .3s;
        margin-bottom: 7px;

        a {
            display: block;
            position: relative;

            &:before {
                content: '';
                display: block;
                // padding-top: calc(330 / 768 * 100%);
            }

            img {
                margin: auto;
                max-width: 100%;
                border-radius: 16px;
            }
        }

        &:not(.swiper-slide-active) {
            transform: translateY(7px);
            opacity: .5;
            transform: scale(.8);
        }
    }

    @include media-up(375) {
        .swiper-slide {
            a {
                img {
                    border-radius: rwd-val(375px, 16px, 768px, 32px);
                }
            }
        }
    }

    @include media-up(768) {
        .swiper-slide {
            a {
                img {
                    border-radius: 32px;
                }
            }
        }
    }
}


.index-menu-bed {
    position: relative;
    margin-bottom: calc(15 / 14 * 1rem);
    display: flex;

    &:before {
        content: '';
        display: block;
        // padding-top: calc(170 / 345 * 100%);
    }

    .link-inner{
       width: 100%;
       background: #fff;
       border-radius: 16px;
       padding:15px 0;
       box-shadow: 0 1px 16px #1098CB4D;
    }

    .bed-inner {
        // @include absolute(0, 0, 0, 0);
    }

    .bed-bg {
        display: block;
        width: 100%;
        filter: drop-shadow(#{$shadow-light});
        // @include absolute(0, 0, 0, 0);
    }

    .btn-link-disable-overlay {
        position: absolute;
        bottom: 4px;
        left: 50%;
        transform: translateX(-50%);
        width: calc(216 / 345 * 100%);
    }

    .bed-link {
        color: $color-font;
        text-align: center;
        position: relative;
        z-index: 1;
        width: calc(50% - 5px);

        img {
            height: 40px;
            width: auto;
        }

        .link-txt {
            font-size: calc(16 / 14 * 1rem);
            letter-spacing: calc(3.2 / 16 * 1em);
            font-weight: 500;
            margin-top: 6px;
            line-height: 18px;
            min-height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        @include media-up(376) {
            // .img-1 {
            //     width: rwd-val(376px, 26px, 768px, 50px);
            // }

            // .img-2 {
            //     width: rwd-val(376px, 40px, 768px, 80px);
            // }

            // .img-3 {
            //     width: rwd-val(376px, 28px, 768px, 56px);
            // }

            // .img-4 {
            //     width: rwd-val(376px, 43px, 768px, 86px);
            // }

            // .link-txt {
            //     font-size: rwd-val(376px, 16px, 768px, 24px);
            // }
        }
    }
    .bed-link+.bed-link{ margin-left: 10px;}

    .bed-link_disabled {
        opacity: 0.3;
        pointer-events: none;

        img {
            filter: grayscale(1)
        }
    }

    .link-1 {

    }

    .link-2 {

    }

    // .link-3 {
    //     bottom: 0;
    //     top: 50%;
    //     left: 50%;
    //     transform: translateX(-50%);
    //     width: 50%;
    // }

    // .link-4 {
    //     top: 17%;
    //     bottom: 17%;
    //     left: 0;
    //     width: 32%;
    // }
}

.ios,
.md {
    .navbar {
        .navbar-inner {
            .round-icon-btn {
                min-width: auto;
                border: 1px solid #fff;
                border-radius: 50%;
                background: $color-primary;
                @include flex(center, center);
                width: calc(26 / 14 * 1rem);
                height: calc(26 / 14 * 1rem);
                font-size: calc(17 / 14 * 1rem);
                color: #fff;
                box-shadow: $shadow-light;
            }
        }
    }

    .navbar-index {
        --f7-navbar-height: var(--navbar-height);

        .title {
            font-weight: bold;
            font-size: calc(20 / 14 * 1rem);
        }
    }
}

.fleet-info-preview-box {
    --side-width: 1.8em;

    margin-bottom: calc(17 / 14 * 1rem);
    position: relative;
    filter: drop-shadow(#{$shadow-light});

    .box-back {
        background: $color-primary;
        border-radius: 16px;
        @include absolute(0, 0, 0, 0);
    }

    .box-front {
        background: #fff;
        border-radius: 16px;
        margin-left: var(--side-width);
        position: relative;

        display: flex;
        padding: 15px 10px;

        .front-left {
            flex-grow: 1;
            display: block;
        }

        .front-right {
            flex-grow: 7;
            max-width: 280px;
        }
    }

    .box-status {
        font-weight: bold;
        max-width: var(--side-width);
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #fff;
    }


    .box-info-item {
        display: flex;
        align-items: flex-start;

        +.box-info-item {
            margin-top: calc(10 / 14 * 1rem);
        }

        .item-title {
            font-size: 1rem;
            letter-spacing: calc(3.5 / 14 * 1em);
            font-weight: 500;
            background: rgba($color-primary, 0.3);
            color: $color-font;
            padding: 2px 6.5px;
            border-radius: 8px;
            min-width: 45px;
            box-sizing: border-box;
            text-align: center;
            margin-right: 9px;
        }

        .item-content {
            font-size: calc(16 / 14 * 1rem);
            color: $color-font-gray;
            padding-top: 0.05em;
            font-weight: 500;
        }
    }

    .box-tools {
        display: flex;
        align-items: stretch;
        width: 100%;
        height: 100%;

        .tools-item {
            flex-grow: 1;
            text-align: center;
            @include flex(center, center);
            position: relative;

            &.active-state {
                &:before {
                    opacity: 1;
                }
            }

            &:before {
                content: '';
                display: block;
                @include absolute(0, 3px, 0, 3px);
                background: rgba($color-primary, 0.1);
                opacity: 0;
                transition: .3s;
                border-radius: 7px;
            }

            * {
                pointer-events: none;
            }

            +.tools-item {
                border-left: 2px dotted $color-border;
            }
        }

        .tools-msg {
            position: relative;
            margin-right: 5px;

            img {}

            .muki-num-badge {
                right: -17px;
            }
        }

        @include media-up(376) {
            .img-msg {
                width: rwd-val(376px, 28px, 768px, 56px);
            }

            .img-phone {
                width: rwd-val(376px, 27px, 768px, 54px);
            }
        }
    }
}

.fast-appoint-wrap{
    border:1px #4D9FC2 solid;
    background: #fff;
    overflow: hidden;
    border-radius: 16px;

    .base-list{
        border-top:2px #c9e2ec solid;
    }
}

.fast-appoint {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    color: #4D9FC2;

    
    

    .appoint-title {
        font-weight: bold;
        font-size: calc(18 / 14 * 1rem);
        letter-spacing: calc(1.26 / 18 * 1em);
        padding: 10px 0 0 10px;
    }

    .muki-tabbar.sty-tiny-space{
        padding:0;
    }

    .toolbar {
        width: 125px;
        margin-bottom: 0;
        background: #EDF5F9;
        border: 1px #D8E0E3 solid;
        margin: 10px 10px 0 0;

        .tab-link {
            // semibold
            font-weight: 600;
        }
        .tab-link-content{
            text-shadow: none;
            font-size: 14px;
            font-weight: normal;
            color: #000;
        }
        .tab-link-acitve{
            color:#fff;
        }
        .tab-link-bg-hilighter{
            background: #4D9FC2;
        }
    }

    
}

.new-logo{
    display: inline-block;
    background: #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    position: relative;
    overflow: hidden;
    img{
       position: absolute;
       left: 50%;
       top: 50%;
       transform: translate(-50%,-50%); 
       width: 40px;
       height: 40px;
    }
}

.bar-inner{
    border: 0 !important;
}

.list-item{
    border-bottom: 1px #ddd dashed;
}
</style>
