<template>
    <f7-page name="login" class="p-login" no-toolbar no-navbar>
        <!-- Top Navbar -->
        <f7-navbar noShadow noHairline class="navbar-no-bg-always" transparent>
            <f7-nav-left />
            <f7-nav-title>
            </f7-nav-title>
            <f7-nav-right>
                <f7-link class="btn-envelope">
                </f7-link>
            </f7-nav-right>
        </f7-navbar>

        <!-- Page content-->
        <ContentScroller fillHeight>
            <template #body>
                <div>
                    <div class="logo-area">
                        <BaseSpacer h="30px" />
                        <div class="page-inner">
                            <div class="logo-wrap">
                                <img class="area-logo" src="@/assets/images/logo/logo.svg" alt="logo" />
                            </div>
                            <div class="area-title">
                                南投愛行
                            </div>
                        </div>
                        <BaseSpacer h="15px" />
                    </div>

                    <form
                        class="form-list list-v-gap-27px"
                        @submit="onFormSubmit"
                    >
                        <div class="page-inner page-inner-30px">
                            <BaseSpacer h="32px" />

                            <BaseInput
                                sty="round-bar"
                                name="UserName"
                                inputmode="tel"
                                type="text"
                                placeholder="請輸入手機號碼"
                                v-model.trim="values.UserName"
                                h="55px"
                                fz="20px"
                            />

                            <BaseInput
                                sty="round-bar password"
                                name="Mima"
                                type="password"
                                placeholder="請輸入密碼"
                                noPasswordToggler
                                v-model="values.Mima"
                                h="55px"
                                fz="20px"
                            />

                            <BaseButton
                                class="btn-theme"
                                h="45px"
                                pill
                                loginRegister
                                :disabled="pageData.btnDisabled"
                                type="submit"
                            >
                                登入
                            </BaseButton>
                            <div class="text-red" style="padding-top: 5px;">
                                {{pageData.loginFailMsg}}
                            </div>

                            <BaseSpacer h="45px" class="h45" />

                            <div class="text-align-center">
                                <f7-link class="login-page-alternative-link" href="/register">
                                    註冊會員
                                </f7-link>
                            </div>

                            <BaseSpacer h="30px" class="h30" />

                            <div class="text-align-center">
                                <f7-link class="login-page-alternative-link forget-quest" href="/forgot_pwd">
                                    忘記密碼?
                                </f7-link>
                            </div>

                            <BaseSpacer h="40px" class="h40" />
                            
                        </div>
                    </form>
                </div>
            </template>
            <template #bottom>
                <div class="page-inner">
                    <div class="text-align-center">
                        <span class="version-text">
                            版本：{{pageData.mobileInfo ? pageData.mobileInfo.displayVersion : '-'}}
                        </span>
                    </div>
                    <BaseSpacer h="15px" />
                </div>
            </template>
        </ContentScroller>
        <!-- Page content / -->
    </f7-page>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
// import { useI18n } from "vue-i18n";
import { useStore } from 'vuex'
import { useForm } from "vee-validate";
import { Counter, intervalGetMobileInfo } from '@functions'
import { Auth } from "@/js/services/baseService";
import ContentScroller from "@/components/ContentScroller";
import { $ } from "dom7";

export default {
    components: {
        ContentScroller
    },
    props: {
        f7router: Object,
    },
    setup(props) {
        // 多國語系
        // const { t, locale} = useI18n({
        //     messages: {
        //         "tw": {
        //         },
        //         en: {
        //         },
        //     }
        // });

        const { f7router } = props;

        // store
        const store = useStore();

        const pageData = reactive({
            btnDisabled: false,
            loginFailMsg: '',
            mobileInfo: {}
        })

        // form驗證設定
        const {
            errors,
            isSubmitting,
            submitCount,
            meta,
            values,
            setFieldError,
            setErrors,
            setFieldValue,
            setValues,
            validate,
            validateField,
            handleSubmit,
            submitForm,
            handleReset,
            resetForm
        } = useForm({
            validateOnMount: false,
            validationSchema: {
                UserName: {
                    required: true,
                    verifyMobile: true,
                },
                Mima: {
                    required: true,
                },
            },
            initialValues: {
            },
        });

        onMounted(()=>{
            // 測試用資料
            if ( process.env.NODE_ENV == 'development' && true ) {
                values.UserName = '0985099654'
                values.Mima = '123456'
            }
        })


        function login(keyNumber){
            const { UserName, Mima } = values;
            const postData = {
                UserName: UserName,
                Mima: Mima,
                // 推播key
                KeyNumber: keyNumber,
                ClientId: mukiConst.CLIENT_ID,
                ClientSecret: mukiConst.CLIENT_SECRET,
            };

            return store.dispatch('auth/login', postData).then((res)=>{

                ajax200Res(
                    res,
                    (res, data, msg)=>{
                        // 成功
                        pageData.loginFailMsg = '';
                    },
                    (res, data, msg)=>{
                        // 例外失敗
                        if ( msg ) {
                            pageData.loginFailMsg = msg
                        }

                        pageData.btnDisabled = false;

                    }
                )

            }).catch(err => {
                const { response } = err;

                if ( response ) {
                    const { data, status } = response;
                    const { Success, Message } = data;
    
                    if ( Message ) {
                        pageData.loginFailMsg = Message
                    }

                } else {

                    if (err.code === 'ECONNABORTED' && err.message && err.message.indexOf('timeout') !== -1) {
                        // request time out will be here
                        pageData.loginFailMsg = '網路連線逾時，請稍候再試'
                    } else {
                        // shutdonw api server
                        pageData.loginFailMsg = '網路連線不穩定，請稍候再試'
                    }
                }

                pageData.btnDisabled = false;
            })
        }

        
        // mobile Info
        intervalGetMobileInfo().then(res=>{
            if ( res ) {
                pageData.mobileInfo = res;

                if ( _deviceType() === 2 ) {
                    // ios 版本號是放在 application_version
                    pageData.mobileInfo.displayVersion = res.application_version
                } else {
                    // android 版本號是放在 application_version_name
                    pageData.mobileInfo.displayVersion = res.application_version_name
                }

            } else {
                consle.log('未正確取得 token ')
            }
        }).catch(()=>{
            console.log('未正確取得手機token ')
        })


        // 驗證帳號
        const onFormSubmit = handleSubmit( async (values, actions) => {

            pageData.btnDisabled = true;

            if ( _deviceType() === 0 ) {
                // 電腦版，測試用登入
                await login(mukiConst.PUSH_KEY_NUMBER)
            } else {

                if ( !pageData.mobileInfo['token'] ) {

                    _alert_custom('提示', '未正確取得KeyNumber，請重新開啟App')

                    return;
                }

                await login(pageData.mobileInfo['token'])
            }

            pageData.btnDisabled = false;


        });

        return {
            pageData,
            store,
            values,
            onFormSubmit,
            m_direct_page,
        }
    }
};

</script>

<style lang="scss">
@import "~@/css/variables";
@import "~@/css/mixins";
@import "~@/css/grid";

.p-login{

    .page-content{
        background: url('@/assets/images/background/login_bg.svg')center -50px no-repeat;
        background-size: 100% auto; 
    }

    .base-spacer.h45{
        @include media(375){
            max-height: rwd-val(320px, 20px, 375px, 45px);
        }
        @include media(320){
            max-height: 20px;
        }
    }
    .base-spacer.h40{
        @include media(375){
            max-height: rwd-val(320px, 20px, 375px, 40px);
        }
        @include media(320){
            max-height: 20px;
        }
    }
    .base-spacer.h30{
        @include media(375){
            max-height: rwd-val(320px, 20px, 375px, 30px);
        }
        @include media(320){
            max-height: 20px;
        }
    }

    .area-logo {
        width: 100%;
    }
    
}

.ios, .md{
    .dialog-text{
        word-break: break-all;
    }
}

.version-text{
    font-size: 14px;
    letter-spacing: 0.1em;
    color: #A4A4A4;
}

.forget-quest{
    padding-left: 1em;
}

.p-login{
    .form-list{
        .input-group{
            background:#F1F8FA !important;
            border: 2px rgba(77, 159, 194, .15) solid !important;
            &.active{
                border: 2px rgba(77, 159, 194, 1) solid !important;    
            }
        }
    }
}



</style>
