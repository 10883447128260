import { Order } from "@baseService";
import { computed, reactive } from "vue";

export default {
    namespaced: true,
    state: () => ({
        // api 通用

        // 是否愛心計程車 
        caringEasyCar: false,

        // 放趟資訊
        ServiceType: '',
        // 付款類型
        PayType: '',
        CompanyId: '',
        VehicleModelId:'',

        // 上車地點ˇ
        StartAddress: '',
        BookingStartLat: '',
        BookingStartLong: '',
        AdditionMessage: '',
        // 下車地點
        TargetAddress: '',
        BookingTargetLat: '',
        BookingTargetLong: '',
        TargetAdditionMessage: '',
        
        // 乘車人數
        PassengerCount: '',
        // 預估時間
        EstimatedTime: '',
        estimatedTimeInSeconds: '',
        // 預估里程
        Distance: '',
        distanceInMeters: '',
        // 預估車資 - 最低
        LowestEstimatedFare: '',
        // 預估車資 - 最高
        HighestEstimatedFare: '',

        // ==== conditions ====
        // 有輪椅
        condition_type_0: false,
        // 輪椅較大
        condition_type_1: false,
        // 使用愛心卡
        useLoverCard: false,
        // 需聯絡陪伴者 (客戶新增，不修改API，直接在送出時加到 AdditionMessage )
        contactCompanion: false,
        // ==== conditions ==== /

        // 陪伴者姓名
        companionName: '',
        // 陪伴者電話
        companionPhone: '',

        // 車隊
        selectedFleet: {
            CanBookingTrips: '',
            CompanyId: '',
            CompanyLogoUrl: '',
            FleetName: '' ,
            ShiftTime: '',
            ShiftTimeId: '',
            TotalTrips: '',
            VehicleModelId: '',
            VehicleModelLogoUrl: '',
            VehicleModelName: '',
            ServiceType: ''
        },
        // 車隊 /

        // 優惠券
        selectedCoupon: {
        },

        // // 司機
        // DriverInfo: '',

        // ==== 其他共用 ====
        bookingDate:'',
        bookingTime:'',
        bookingDateTimeISO:'',

        // ==== 一般預約訂車 ====
        // 乘車時間
        BookingDate: '',
        BookingTime: '',
        bookingTime: '',
        // 乘車時間
        rideTime: '',
        // 是否候補
        bookIsWait: false,
        // ==== 一般預約訂車 / ====

        // ==== 臨時訂車 ====
        // ==== 臨時訂車 / ====

        // ==== 固定時段訂車 ====
        bookPeriodStart: '',
        bookPeriodEnd: '',
        BookingDates: [
            // 開始日期,
            // 結束日期
        ],
        bookingWeekdays: [],
        bookPeriodAgreeWait: false,
        // ==== 固定時段訂車 / ====
    }),
    getters: {
        getBookingData(state, getters){
            var result = reactive({})

            for (let key in state){
                result[key] = computed(()=> state[key])
            }
            
            return result
        },
        getLocation(state, getters){
            return reactive({
                StartAddress: computed(() => state.StartAddress),
                TargetAddress: computed(() => state.TargetAddress),
            })
        },
    },
    actions: {
        init({commit}, data) {
            commit('clearBookingData');
        },
    },
    mutations: {
        setBookingData(state, data){
            for ( let key in state){
                if ( data[key] !== undefined ) {
                    state[key] = data[key]
                }
            }
        },
        setLocation(state, data){
            for ( let key in state){
                if ( data[key] !== undefined ) {
                    state[key] = data[key]
                }
            }
        },
        clearBookingData(state, data){
            for ( let key in state){
                state[key] = ''
            }
        },
    },
}

