<template>
    <div
        class="base-grape"
        :class="[
            {'sty-single-line-align-right': singleLineRight},
            {'sty-h-center': hCenter},
        ]"
        :style="[
            `--base-grape-gap:${gap}`,
            inline ? `--grape-inner-display:inline-flex` : ''
        ]"
    >
        <div class="grape-inner">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import { computed } from "vue";
export default {
    props:{
        gap:{
            type:[String, Number],
            default: '10px'
        },
        inline:{
            type: Boolean,
            default: false,
        },
        // 單行靠右
        singleLineRight:{
            type: Boolean,
            default: false,
        },
        // 水平置中
        hCenter: Boolean
    },
    setup(props, context){
        return {
        }
    }
};
</script>

<style lang="scss">
@import "~@/css/variables";
@import "~@/css/mixins";
@import "~@/css/grid";

.base-grape{
    --base-grape-gap: 10px;
    --grape-inner-display: flex;
    margin-bottom: 12px;

    &.sty-h-center{
        .grape-inner{
            justify-content: center;
        }
    }

    .grape-inner{
        margin-bottom: calc(var(--base-grape-gap) * -1);
        display: var(--grape-inner-display);
        flex-wrap: wrap;
    }
    
    .grape-item{
        margin-bottom: var(--base-grape-gap);
        margin-right: var(--base-grape-gap);
    }

    &.sty-single-line-align-right{
        .grape-item{
            &:last-child{
                margin-right: 0;
            }
        }
    }

}

</style>
