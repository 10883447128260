<template>
    <div
        class="no-data-box"
        :class="[{
            'size-sm':size == 'sm',
            'sty-page': sty == 'page'
        }]"
        :style="{color: textColor? textColor : ''}"
    >
        <div class="no-data-icon" v-if="media">
            <div class="inner-wrap">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="209.075" height="150" viewBox="0 0 209.075 150">
                    <defs>
                        <linearGradient id="linear-gradient11" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                        <stop offset="0" stop-color="#94c5da"/>
                        <stop offset="1" stop-color="#4d9fc2"/>
                        </linearGradient>
                    </defs>
                    <g id="no_data" data-name="no data" transform="translate(-155.089 -233.306)">
                        <path id="Path_4796" data-name="Path 4796" d="M206.257,646.943c1.478-1.188,1.938-4.562.605-6.464s-5.683-2.816-7.75-1.835a12.968,12.968,0,0,0-3.059,1.906,24.172,24.172,0,0,0-1.9-3.67c-1.472-2.461-8.1-4.75-12.525-3.379a9.422,9.422,0,0,0-5.834,5.5c-9.88-6.138-13.23,3.016-13.23,3.016-3.484-1.205-6.138-.451-6.993,1.15a3.219,3.219,0,0,0,.209,3.7C172.662,647.876,190.261,649.02,206.257,646.943Z" transform="translate(0 -347.057)" fill="#231815" opacity="0.08"/>
                        <path id="Path_4797" data-name="Path 4797" d="M534.816,262.145c3.71-9.332-4.917-12.843-10.534-10.321,0,0-.428-8.6-5.017-10.268-7.211-2.619-10.685,1.4-11.083,4.259a26.047,26.047,0,0,0-24.005-12.477c-17.008.761-15.205,17.686-15.205,17.686s-3.95-4.753-9.853-2.661c-4.75,1.683-6.768,7.132-5.665,11.618C475.749,263.5,508.19,264.632,534.816,262.145Z" transform="translate(-258.76 0)" fill="#231815" opacity="0.08"/>
                        <path id="Path_4798" data-name="Path 4798" d="M1275.318,708.652a3.386,3.386,0,0,1-.348-5q.072-.074.147-.146a6.752,6.752,0,0,1,7.634-1.048,8.044,8.044,0,0,1,3.072,2.614,12.735,12.735,0,0,1,1.378-5.891c1.454-2.412,7.435-4.869,11.779-3.509a11.358,11.358,0,0,1,6.284,5.282c9.723-6,12.982,3.006,12.982,3.006,3.425-1.172,6.029-.423,6.864,1.153a3.161,3.161,0,0,1-.217,3.637C1308.307,709.679,1291.02,710.745,1275.318,708.652Z" transform="translate(-961.411 -401.048)" fill="#231815" opacity="0.08"/>
                        <g id="Group_2046" data-name="Group 2046" transform="translate(214.367 259.682)">
                        <path id="Path_4799" data-name="Path 4799" d="M627.506,433.3c.909,22.045-14.087,71.5-22.94,97.5,24.07,4.866,48.226,9.558,73.429,12.11,5.707-5.1,20.485-78.181,17.144-102.445Z" transform="translate(-604.566 -433.299)" fill="#cce0e8"/>
                        <g id="Group_2045" data-name="Group 2045" transform="translate(19.671 16.375)">
                            <rect id="Rectangle_1105" data-name="Rectangle 1105" width="1.854" height="52.831" transform="matrix(0.15, -0.989, 0.989, 0.15, 10.161, 1.833)" fill="#fff"/>
                            <rect id="Rectangle_1106" data-name="Rectangle 1106" width="1.854" height="52.831" transform="matrix(0.15, -0.989, 0.989, 0.15, 8.06, 12.898)" fill="#fff"/>
                            <rect id="Rectangle_1107" data-name="Rectangle 1107" width="1.854" height="52.831" transform="translate(6.518 22.392) rotate(-81.358)" fill="#fff"/>
                            <rect id="Rectangle_1108" data-name="Rectangle 1108" width="1.854" height="52.831" transform="translate(4.215 33.071) rotate(-81.358)" fill="#fff"/>
                            <rect id="Rectangle_1109" data-name="Rectangle 1109" width="1.854" height="52.831" transform="matrix(0.15, -0.989, 0.989, 0.15, 1.717, 43.752)" fill="#fff"/>
                            <rect id="Rectangle_1110" data-name="Rectangle 1110" width="1.854" height="33.367" transform="translate(0 54.421) rotate(-81.358)" fill="#fff"/>
                        </g>
                        </g>
                        <g id="Group_2048" data-name="Group 2048" transform="translate(195.011 266.365)">
                        <path id="Path_4800" data-name="Path 4800" d="M484.17,483.973c-.181,20.952-16.744,67.145-26.371,91.4,22.6,5.754,45.289,11.347,69.073,14.96,5.654-4.565,23.129-73.184,21.108-96.356Z" transform="translate(-457.799 -483.973)" fill="#edf5f9"/>
                        <path id="Path_4805" data-name="Path 4805" d="M480.63,483.973c-.156,18.14-14.5,58.132-22.831,79.126,19.566,4.982,39.21,9.824,59.8,12.951,4.895-3.952,20.024-63.359,18.274-83.421Z" transform="translate(-451.735 -476.834)" fill="#fff"/>
                        <g id="Group_2047" data-name="Group 2047" transform="translate(21.875 17.692)">
                            <rect id="Rectangle_1111" data-name="Rectangle 1111" width="1.649" height="46.991" transform="translate(11.357 1.616) rotate(-78.502)" fill="#f1f5ef"/>
                            <rect id="Rectangle_1112" data-name="Rectangle 1112" width="1.649" height="46.991" transform="matrix(0.199, -0.98, 0.98, 0.199, 9, 11.353)" fill="#f1f5ef"/>
                            <rect id="Rectangle_1113" data-name="Rectangle 1113" width="1.649" height="46.991" transform="translate(7.21 19.718) rotate(-78.502)" fill="#f1f5ef"/>
                            <rect id="Rectangle_1114" data-name="Rectangle 1114" width="1.649" height="46.991" transform="translate(4.691 29.102) rotate(-78.502)" fill="#f1f5ef"/>
                            <rect id="Rectangle_1115" data-name="Rectangle 1115" width="1.649" height="46.991" transform="matrix(0.199, -0.98, 0.98, 0.199, 1.998, 38.481)" fill="#f1f5ef"/>
                            <rect id="Rectangle_1116" data-name="Rectangle 1116" width="1.649" height="29.678" transform="translate(0 47.882) rotate(-78.502)" fill="#f1f5ef"/>
                        </g>
                        </g>
                        <g id="Group_2049" data-name="Group 2049" transform="translate(258.563 292.221)">
                        <path id="Path_4801" data-name="Path 4801" d="M929.211,754.93a24.026,24.026,0,1,0-32.516-9.871A24.031,24.031,0,0,0,929.211,754.93Z" transform="translate(-889.943 -705.796)" fill="#e0e0e0" opacity="0.3"/>
                        <path id="Path_4802" data-name="Path 4802" d="M952.529,781.4a4.485,4.485,0,1,0-2.634-5.3A4.2,4.2,0,0,0,952.529,781.4Z" transform="translate(-938.446 -760.463)" fill="#fff"/>
                        <path id="Path_4803" data-name="Path 4803" d="M884.017,734.709a27.83,27.83,0,0,0,18.508-.808l.942,1.771,1.441,2.681.556,1.045,5.38-2.879-2.933-5.495a27.929,27.929,0,1,0-23.894,3.686Zm-12.5-15.722a23.376,23.376,0,1,1,31.636,9.6A23.372,23.372,0,0,1,871.517,718.987Z" transform="translate(-864.192 -680.029)" fill="url(#linear-gradient11)"/>
                        <path id="Path_4804" data-name="Path 4804" d="M1184.7,1138.291l1.334-.712a6.237,6.237,0,0,0,2.562-8.44l-11.755-22a6.237,6.237,0,0,0-8.44-2.562l-1.333.712a6.237,6.237,0,0,0-2.562,8.44l11.755,22A6.237,6.237,0,0,0,1184.7,1138.291Z" transform="translate(-1124.263 -1047.943)" fill="url(#linear-gradient11)"/>
                        </g>
                    </g>
                </svg>
            </div>
		</div>
        <div class="no-data-title">
			{{title}}
		</div>
        <!-- <div class="no-data-des">
			{{text}}
		</div> -->
	</div>
</template>

<script>
export default {
    props:{
        sty: {
            default:''
        },
        size:{
            default:''
        },
        title: {
            default:'No Data'
        },
        textColor:{
            default:'',
        },
        media:{
            type: Boolean,
            default: true,
        }
    }
};
</script>

<style lang="scss">
@import "~@/css/variables";
@import "~@/css/mixins";
@import "~@/css/grid";
    // no-data-box
.no-data-box{
    @include flex(center,center);
    flex-direction: column;
    padding:10px;
    border-radius: 10px;

    .no-data-icon{
        width: 209px;
        position:relative;
        margin-bottom: 20px;
        @include flex(center,center);
    }

    .no-data-title{
        color: #898989;
        font-size: 16px;
        letter-spacing: 0.1em;
        font-weight: bold;
    }

    &.sty-page{
        margin-top: 21vh;
    }

    &.size-sm{
        .no-data-icon{
            max-width: 110px;
            margin-top: 15px;
        }
        .no-data-title{
            font-size: 18px;
            margin-bottom: 0;
        }
        .no-data-des{
            font-size: 14px;
        }
    }

    @include media(374.98){
        &:not(.size-sm) {
            .no-data-icon{
                max-width: 140px;
            }
        }
    }
}

</style>
